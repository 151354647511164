import DetailsContainer from "../../../../common/components/DetailsPage/components/DetailsContainer";
import { Box, Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type ReportSectionProps = {
  containerTitle: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  titleIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  data: any;
};

type ItemType = {
  title: string;
  data: {
    name: string;
    value: string;
  }[];
};

const ReportSection = ({
  containerTitle,
  titleIcon,
  data,
}: ReportSectionProps) => {
  return (
    <DetailsContainer title={containerTitle} titleIcon={titleIcon}>
      {data?.map((item: ItemType) => (
        <Box key={item.title} m={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }} mb={1}>
            {item.title}
          </Typography>
          <Grid container spacing={1}>
            {item.data.map((detail) => (
              <Grid item xs={12} sm={6} lg={4} key={detail.name}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {detail.name}
                </Typography>
                <Typography variant="body2">{detail.value || "n/a"}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </DetailsContainer>
  );
};

export default ReportSection;
