import { useState } from "react";
import "./Sidebar.scss";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import logoLong from "../../../assets/images/logoLong.svg";
import logoShort from "../../../assets/images/logoShort.svg";
import NavItem from "./NavItem";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <div className="sidebar-inner">
        <div>
          <img src={open ? logoLong : logoShort} alt="" />
          <NavItem text="użytkownicy" path="/users" />
          <NavItem text="analizy" path="/analyses" />
        </div>
        <div>
          <div className="toggle-btn">
            <IconButton onClick={toggleDrawer}>
              {open ? <ArrowCircleLeft /> : <ArrowCircleRight />}
            </IconButton>
          </div>
          <NavItem text="wyloguj" path="/logout" />
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
