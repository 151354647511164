import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ListContainer from "../../../common/components/ListContainer/ListContainer";
import { analysesStatuses, getStatusLabel } from "../../../common/helpers";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getAnalysesList } from "./AnalysesList.reducers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArticleIcon from "@mui/icons-material/Article";

type ReportItem = {
  id: number;
  name: string;
  created_at: string;
  status: string;
  plots: any;
  owner_id: string;
};

const AnalysesTable = () => {
  const dispatch = useAppDispatch();
  const {
    total,
    items: analysesList,
    loading,
    error,
  } = useAppSelector((state) => state.analyses);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 0;
  const limit = searchParams.get("limit") || "10";
  const ordering = searchParams.get("ordering") || "created_at";
  const statusFilter = searchParams.get("statusFilter") || "all";
  const searchQuery = searchParams.get("searchQuery") || "";

  const currentSearchParams = {
    page,
    limit,
    ordering,
    statusFilter,
    searchQuery,
  };

  useEffect(() => {
    const fetchReportsList = async () => {
      await dispatch(
        getAnalysesList({
          searchQuery,
          statusFilter,
          page,
          limit,
          ordering,
        })
      );
    };
    fetchReportsList();
  }, [dispatch, statusFilter, limit, ordering, page, searchQuery]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const changePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => {
    setSearchParams({ ...currentSearchParams, page: value.toString() });
  };

  const changeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParams({
      ...currentSearchParams,
      page: "0",
      limit: parseInt(event.target.value, 10).toString(),
    });
  };

  const currentOrderBy = ordering.charAt(0) === "-";

  const sortByDate = () => {
    setSearchParams({
      ...currentSearchParams,
      page: "0",
      ordering: currentOrderBy ? "created_at" : "-created_at",
    });
  };

  const filterByStatus = ({ value }: any) => {
    setAnchorEl(null);
    setSearchParams({
      ...currentSearchParams,
      page: "0",
      statusFilter: value,
    });
  };

  if (error) {
    return (
      <h1 className="state-msg">
        wystąpił błąd przy wczytywaniu listy raportów
      </h1>
    );
  }

  return (
    <ListContainer
      title="analizy"
      loading={loading}
      titleIcon={ArticleIcon}
      currentSearchParams={currentSearchParams}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ height: "75px" }}>
              <TableCell>nr działki</TableCell>
              <TableCell className="status">
                {statusFilter === "all" && !searchParams.get("statusFilter")
                  ? "status"
                  : analysesStatuses.find(
                      (status) => status.value === statusFilter
                    )?.label}
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <FilterListIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={currentOrderBy ? "desc" : "asc"}
                  onClick={sortByDate}
                  active={true}
                >
                  data
                </TableSortLabel>
              </TableCell>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <FormGroup>
                  {analysesStatuses.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      onClick={() => filterByStatus({ value, label })}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </FormGroup>
              </Menu>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analysesList?.map(
              ({
                id,
                name,
                created_at,
                status,
                plots,
                owner_id,
              }: ReportItem) => (
                <TableRow key={id}>
                  <TableCell>{name || "-"}</TableCell>
                  <TableCell>{getStatusLabel(status) || "-"}</TableCell>
                  <TableCell>
                    {created_at &&
                      new Intl.DateTimeFormat("pl-PL", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(created_at))}
                  </TableCell>

                  <TableCell align="center">
                  
                      <Link
                        to={`/analyses/${id}?owner_id=${owner_id}`}
                      >
                        <Button
                          endIcon={<ArrowForwardIcon />}
                          variant="outlined"
                          sx={{ textTransform: "none" }}
                        >
                          zobacz
                        </Button>
                      </Link>
                    
                  </TableCell>
                </TableRow>
              )
            )}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={4}
                count={total}
                rowsPerPage={Number(limit)}
                page={page}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
                labelRowsPerPage="ilość wierszy"
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ListContainer>
  );
};

export default AnalysesTable;
