import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { signOut } from "../Auth.reducers";

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      await dispatch(signOut());
      navigate("/login");
    };

    logout();
  }, [dispatch, navigate]);

  return <></>;
};

export default Logout;
