import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { PublicRoute } from "./common/components/Routers/PublicRoute";
import { initUser } from "./lib/Auth/Auth.reducers";
import Login from "./lib/Auth/Login/Login.page";
import { useAppDispatch } from "./redux/hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "./common/components/Routers/PrivateRoute";
import AppLayout from "./common/components/AppLayout/AppLayout";
import UsersList from "./lib/Users/UsersList/UsersList.page";
import AnalysesList from "./lib/Analyses/AnalysesList/AnalysesList.page";
import Logout from "./lib/Auth/Logout/Logout.page";
import SingleUser from "./lib/Users/SingleUser/SingleUser";
import SingleReport from "./lib/Analyses/SingleReport/SingleReport";
import ErrorPage from "./lib/Error/ErrorPage";
import ForgotPassword from "./lib/Auth/ForgotPassword/ForgotPassword";
import NewPassword from "./lib/Auth/ForgotPassword/NewPassword";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initUser());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ToastContainer limit={5} />
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="new-password" element={<NewPassword />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<AppLayout />}>
            <Route path="users" element={<UsersList />} />
            <Route path="users/:id/*" element={<SingleUser />} />
            <Route path="analyses" element={<AnalysesList />} />
            <Route path="analyses/:id/*" element={<SingleReport />} />
          </Route>
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
