import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LogoLong from "../../assets/images/logoLong.svg";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        textAlign: "center",
      }}
    >
      <Container maxWidth="md">
        <img src={LogoLong} alt="sunmetric logo" />
        <Typography variant="h1" mt={2}>
          404
        </Typography>
        <Typography variant="h5" mb={3}>
          Taka strona nie istnieje.
        </Typography>
        <Link to="/">
          <Button variant="outlined" size="large">
            Powrót
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default ErrorPage;
