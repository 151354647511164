import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../../config/config";
import { http } from "../../../config/http";

type UsersQueryParams = {
  limit?: string;
  ordering?: string;
  index?: string;
  search?: string;
};

interface User {
  Attributes: Attributes[];
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
}

interface UsersState {
  Users: User[];
  total: number;
  items: any;
  loading: boolean;
  error: boolean;
  success: boolean;
}

const initialState: UsersState = {
  Users: [],
  total: 0,
  items: [],
  loading: false,
  error: false,
  success: false,
};

type Attributes = {
  Name: string;
  number_of_analyses: string;
  Value: string | number;
};

const mapUsersData = (users: User[]) => {
  return users.map((user: User) => {
    const attributes = user.Attributes.reduce(
      (obj: any, item: Attributes) => ((obj[item.Name] = item.Value), obj),
      {}
    );
    const { sub, given_name, family_name, number_of_analyses, is_admin } =
      attributes;

    return {
      id: sub,
      name: `${given_name} ${family_name}`,
      created_at: user.UserCreateDate,
      reportsCount: number_of_analyses,
      isAdmin: is_admin,
    };
  });
};

export const getUsersList = createAsyncThunk<
  UsersState,
  { searchQuery: string; page: number; limit: string; ordering: string }
>(
  "users",
  async ({ searchQuery, page, limit, ordering }, { rejectWithValue }) => {
    const params: UsersQueryParams = {
      limit: limit,
      ordering: ordering,
    };

    if (page) {
      params["index"] = (page * Number(limit)).toString();
    }

    if (searchQuery.length > 0) {
      params["search"] = searchQuery;
    }

    const query = new URLSearchParams(params).toString();

    try {
      const response = await http.get(
        `${API_URL}plot-analysis/users_all?${query}`
      );

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.items = action.payload.Users?.length
        ? mapUsersData(action.payload.Users)
        : [];
      state.error = false;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getUsersList.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});
