import "./Login.styles.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useAppDispatch } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signIn } from "../Auth.reducers";
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { translate } from "../../../common/helpers";
import LoginFlowWrapper from "../../../common/components/LoginFlowWrapper/LoginFlowWrapper";
import { Link as RouterLink } from "react-router-dom";
import { addNewNotification } from "../../../common/components/Notification/Notification";

interface FormValuesProps {
  email: string;
  password: string;
}

type RespType = {
  payload: {
    error: string;
  };
  meta: {
    requestStatus: string;
  };
  type: string;
};

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("updated")) {
      searchParams.delete("updated");
      addNewNotification({
        text: "Hasło zostało zmienione. Możesz się zalogować.",
        variant: NotificationVariant.SUCCESS,
      });
    }
  }, [dispatch, searchParams]);

  const submitForm = async (values: FormValuesProps) => {
    try {
      const result = (await dispatch(
        signIn({ username: values.email, password: values.password })
      )) as RespType;

      if (result.meta.requestStatus !== "fulfilled") {
        addNewNotification({
          text: translate(result.payload.error),
          variant: NotificationVariant.ERROR,
        });
        return;
      }
      navigate("/users");
    } catch (e) {
      if (e instanceof Error) {
        addNewNotification({
          text: translate(e.message),
          variant: NotificationVariant.ERROR,
        });
      }
    }
  };

  return (
    <LoginFlowWrapper title="Logowanie">
      <Formik<FormValuesProps>
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().required("pole jest wymagane"),
          password: Yup.string().required("pole jest wymagane"),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
        component={(props) => <Form {...props} />}
      />
    </LoginFlowWrapper>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adres email"
        name="email"
        autoComplete="email"
        error={!!errors.email && touched.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email ? errors.email : ""}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Hasło"
        type={showPassword ? "text" : "password"}
        error={!!errors.password && touched.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.password && errors.password ? errors.password : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon
                    style={{
                      color: "#9E9E9E",
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Zapamiętaj mnie"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Zaloguj się
      </Button>
      <Link to="/forgot-password" variant="body2" component={RouterLink}>
        Zapomniałeś hasła?
      </Link>
    </Box>
  );
};

export default Login;
