import {
  Container,
  Stack,
  SvgIconTypeMap,
  Typography,
  CircularProgress,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Props = {
  title: string;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  titleIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  loading?: boolean;
  error?: boolean;
};

const DetailsPageWrapper = ({
  children,
  title,
  titleIcon,
  loading,
  error,
}: Props) => {
  const TitleIcon = titleIcon;

  return (
    <Container maxWidth="lg" sx={{ my: 6 }}>
      <Stack direction="row" alignItems="center" gap={1} paddingBottom={3}>
        <TitleIcon fontSize="large" color="primary" />
        <Typography variant="h4">{title}</Typography>
      </Stack>
      {!error ? (
        loading ? (
          <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
        ) : (
          children
        )
      ) : (
        <h2>Nie udało się pobrać danych. Spróbuj ponownie później.</h2>
      )}
    </Container>
  );
};

export default DetailsPageWrapper;
