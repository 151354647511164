import axios from "axios";

const http = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const initHttpClient = () => {
  const accessToken = window.localStorage.getItem("access-token");
  http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export { http, initHttpClient };
