import DetailsContainer from "../../../../common/components/DetailsPage/components/DetailsContainer";
import { visuallyHidden } from "@mui/utils";
import { getStatusLabel } from "../../../../common/helpers";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../config/config";
import { http } from "../../../../config/http";

interface Data {
  date: string;
  status: string;
  label: string;
  name: string;
  hasBeenPaid: number;
  registrationNr: number;
  id: number;
  ownerId: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  alignLeft?: boolean;
  nonSortable?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    alignLeft: true,
    disablePadding: true,
    label: "Nazwa Raportu",
  },
  {
    id: "date",
    disablePadding: false,
    label: "Data",
  },
  {
    id: "label",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "hasBeenPaid",
    disablePadding: false,
    label: "Opłacony",
  },
  {
    id: "registrationNr",
    disablePadding: false,
    label: "Ks. wieczysta",
  },
  {
    id: "id",
    disablePadding: false,
    label: "Link",
    nonSortable: true,
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignLeft ? "left" : "right"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.nonSortable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ReportsList = () => {
  const { id } = useParams();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("date");
  const [reportsList, setReportsList] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserReportsList = async () => {
      setLoading(true);

      try {
        const response = await http.get(
          `${API_URL}plot-analysis/tasks_for_user_id?user_id=${id}`
        );
        setReportsList(response.data.items);
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    getUserReportsList();
  }, [id]);

  const rows = reportsList?.map((row: any) => ({
    date:
      row.created_at &&
      new Intl.DateTimeFormat("pl-PL", {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
      }).format(new Date(row.created_at)),
    status: row.status,
    label: getStatusLabel(row.status),
    name: row.name,
    hasBeenPaid: Number(row?.plots?.id && row?.plots?.id.is_paid),
    registrationNr: row?.land_register && !!row?.land_register?.is_paid,
    id: row?.id,
    ownerId: row.owner_id,
  }));

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <DetailsContainer
      title="Lista raportów"
      titleIcon={AnalyticsIcon}
      loading={loading}
    >
      {rows?.length ? (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${row.name}-${row.id}`}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right">{row.label}</TableCell>
                      <TableCell align="right">
                        {row.hasBeenPaid ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CloseIcon color="warning" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.registrationNr ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CloseIcon color="warning" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.id ? (
                          <Link
                            to={`/analyses/${row.id}?owner_id=${row.ownerId}`}
                            target="_blank"
                          >
                            <IconButton color="primary">
                              <LinkIcon />
                            </IconButton>
                          </Link>
                        ) : (
                          <Typography textAlign={"center"}>-</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" my={3}>
          Brak raportów do wyświetlenia.
        </Typography>
      )}
    </DetailsContainer>
  );
};

export default ReportsList;
