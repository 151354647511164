import LogoLong from "../../../assets/images/logoLong.svg";
import { Box, Container, Link, Typography } from "@mui/material";

interface LoginFlowWrapper {
  children: React.ReactNode;
  title: string;
  text?: string;
}

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.sunmetric-dev.pl/">
        Sunmetric
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

const LoginFlowWrapper = ({ children, title, text }: LoginFlowWrapper) => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={LogoLong} alt="sunmetric logo" />
        <Typography component="h1" variant="h5" mt={2}>
          {title}
        </Typography>
        {text && (
          <Typography textAlign="center" mt={1}>
            {text}
          </Typography>
        )}
        <Box>{children}</Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default LoginFlowWrapper;
