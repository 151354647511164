import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { analysesSlice } from "../lib/Analyses/AnalysesList/AnalysesList.reducers";
import { authSlice, initUser } from "../lib/Auth/Auth.reducers";
import { usersSlice } from "../lib/Users/UsersList/UsersList.reducers";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    analyses: analysesSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

store.dispatch(initUser());
