import "./Notification.scss";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import ErrorAlertIcon from "../../../assets/icons/warningAlert.svg";
import SuccessAlertIcon from "../../../assets/icons/successAlert.svg";
import InfoAlertIcon from "../../../assets/icons/finished.svg";
import "react-toastify/dist/ReactToastify.css";
import { MessageProps } from "./Notification.types";

const Message = ({
  notification: { variant, text, coloredText },
}: MessageProps) => {
  return (
    <Box className="notification-wrap">
      <Box className="notification-body">
        <div className="notification-content">
          <img
            src={
              variant === "error"
                ? ErrorAlertIcon
                : variant === "info"
                ? InfoAlertIcon
                : SuccessAlertIcon
            }
            alt="notification icon"
          />
          <Box className="notification-text">
            <span>{text}</span>
            <span className={variant}> {coloredText ? coloredText : ""}</span>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export const addNewNotification = (notification: {
  text: string;
  variant: string;
}) => {
  return toast(<Message notification={notification} />, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: true,
    toastId: notification.text + Math.random(),
    className: `alert-style ${notification.variant}`,
  });
};
