import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./Sidebar.scss";

const getIcon = (text: string) => {
  switch (text) {
    case "użytkownicy":
      return <GroupIcon />;
    case "analizy":
      return <ArticleIcon />;
    case "wyloguj":
      return <ExitToAppIcon />;
    default:
      return <GroupIcon />;
  }
};

interface INavItem {
  text: string;
  path: string;
}

export default function NavItem({ text, path }: INavItem): JSX.Element {
  const location = useLocation();

  const checkIfSelected = (path: string) => {
    return location.pathname === path;
  };

  return (
    <ListItem
      button
      className="nav-item"
      component={Link}
      to={path}
      selected={checkIfSelected(path)}
    >
      <ListItemIcon>{getIcon(text)}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
