import { Formik } from "formik";
import * as Yup from "yup";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import LoginFlowWrapper from "../../../common/components/LoginFlowWrapper/LoginFlowWrapper";

interface FormValuesProps {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const submitForm = async (form: FormValuesProps) => {
    await Auth.forgotPassword(form.email);
    navigate(`/new-password?email=${form.email}`);
  };

  return (
    <LoginFlowWrapper
      title="Nie pamiętasz hasła?"
      text="Podaj adres e-mail wykorzystany przy rejestracji, a otrzymasz instrukcję odzyskiwania hasła."
    >
      <Formik<FormValuesProps>
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("pole jest wymagane")
            .email("adres e-mail jest niepoprawny"),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
        component={(props) => <Form {...props} />}
      />
    </LoginFlowWrapper>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adres email"
        name="email"
        autoComplete="email"
        error={!!errors.email && touched.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email ? errors.email : ""}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Wyślij
      </Button>
      <Link to="/login" variant="body2" component={RouterLink}>
        Wróć do logowania
      </Link>
    </form>
  );
};

export default ForgotPassword;
