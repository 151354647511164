import { Grid, Typography } from "@mui/material";

interface SimpleItemProps {
  title: string;
  data?: string | number | boolean;
}

const SimpleItem = ({ title, data }: SimpleItemProps) => {
  return data ? (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {data}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default SimpleItem;
