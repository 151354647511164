import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  RouteProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";

export const PrivateRoute: React.FC<RouteProps> = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") navigate("/users");
  }, [navigate, pathname]);

  if (isAuthenticated === null) {
    return <></>;
  }
  if (isAuthenticated === false) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};
