import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DetailsContainer from "../../../../common/components/DetailsPage/components/DetailsContainer";
import { useEffect, useState } from "react";
import { http } from "../../../../config/http";
import { API_URL } from "../../../../config/config";
import { useParams } from "react-router-dom";

interface PaymentItemInterface {
  date: string;
  transaction_name: string;
  payment_amount: any;
  status: string;
}

const BillingInfo = () => {
  const { id } = useParams();
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserReportsList = async () => {
      setLoading(true);

      try {
        const { data } = await http.get(
          `${API_URL}payments/transactions_for_user_id?user_id=${id}`
        );
        setBillingData(data.items);
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    getUserReportsList();
  }, [id]);

  return (
    <DetailsContainer
      title="Historia płatności"
      titleIcon={ReceiptIcon}
      loading={loading}
    >
      {billingData.length ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Cena</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingData.map(
                ({
                  transaction_name,
                  date,
                  payment_amount,
                  status,
                }: PaymentItemInterface) =>
                  status === "success" && (
                    <TableRow key={transaction_name}>
                      <TableCell>
                        {`Raport Sunmetric ${transaction_name.split("_")[1]}` ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {new Intl.DateTimeFormat("pl-PL", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(date && date))}
                      </TableCell>
                      <TableCell>
                        {`${
                          payment_amount?.amount && payment_amount?.amount / 100
                        }zł` || "-"}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" my={3}>
          Brak płatności do wyświetlenia.
        </Typography>
      )}
    </DetailsContainer>
  );
};

export default BillingInfo;
