import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { CognitoUserData } from "./Session.types";
import { initHttpClient } from "../../config/http";

export type CompanyData = {
  companyName?: string;
  nip?: string;
  email?: string;
  street?: string;
  houseNr?: string;
  apartmentNr?: string;
  postCode?: string;
  city?: string;
};

export type NaturalPersonData = {
  firstName?: string;
  lastName?: string;
  email?: string;
  street?: string;
  houseNr?: string;
  apartmentNr?: string;
  postCode?: string;
  city?: string;
};

export type InvoiceData = {
  b: CompanyData;
  np: NaturalPersonData;
};

export interface Profile {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  verified?: boolean;
  groups?: string[];
  invoice?: InvoiceData;
}

interface AuthState {
  profile: Profile | null;
  accessToken?: string | null;
  authenticated?: boolean | null;
  error?: SerializedError;
}

const initialState: AuthState = {
  profile: null,
  accessToken: null,
  authenticated: null,
  error: undefined,
};

export const getTokens = async (): Promise<
  Record<"accessToken" | "idToken", string>
> => {
  try {
    const session = await Auth.currentSession();
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
    };
  } catch (ex) {
    throw new Error();
  }
};

const mapUserData = (
  cognito: CognitoUser,
  attribs: CognitoUserAttribute[]
): CognitoUserData => {
  const user = { cognito } as CognitoUserData;
  for (const attrib of attribs) {
    Reflect.set(user, attrib.getName(), attrib.getValue());
  }
  return user;
};

export const signIn = createAsyncThunk<
  AuthState,
  { username: string; password: string }
>("signIn", async ({ username, password }, thunkAPI) => {
  try {
    const userCognito = await Auth.signIn(username, password);
    const userAttributes = !userCognito.challengeName
      ? await Auth.userAttributes(userCognito)
      : [];
    const mappedUserData = mapUserData(userCognito, userAttributes);
    const groups = userCognito.getSignInUserSession()?.getAccessToken().payload[
      "cognito:groups"
    ];
    const tokens = await getTokens();
    window.localStorage.setItem("access-token", tokens.accessToken);
    initHttpClient();
    return {
      authenticated: true,
      accessToken: tokens.accessToken,
      profile: {
        id: mappedUserData.sub,
        email: mappedUserData.email,
        firstName: mappedUserData.given_name,
        lastName: mappedUserData.family_name,
        phone: mappedUserData.phone_number,
        verified: mappedUserData.email_verified === "true",
        groups,
        invoice: {
          np: {
            firstName: mappedUserData["custom:inv:np:first_name"],
            lastName: mappedUserData["custom:inv:np:last_name"],
            email: mappedUserData["custom:inv:np:email"],
            street: mappedUserData["custom:inv:np:street"],
            houseNr: mappedUserData["custom:inv:np:house_nr"],
            apartmentNr: mappedUserData["custom:inv:np:apartment_nr"],
            postCode: mappedUserData["custom:inv:np:post_code"],
            city: mappedUserData["custom:inv:np:city"],
          },
          b: {
            companyName: mappedUserData["custom:inv:b:company_name"],
            nip: mappedUserData["custom:inv:b:nip"],
            email: mappedUserData["custom:inv:b:email"],
            street: mappedUserData["custom:inv:b:street"],
            houseNr: mappedUserData["custom:inv:b:house_nr"],
            apartmentNr: mappedUserData["custom:inv:b:apartment_nr"],
            postCode: mappedUserData["custom:inv:b:post_code"],
            city: mappedUserData["custom:inv:b:city"],
          },
        },
      },
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const signOut = createAsyncThunk("logout", async (_, thunkAPI) => {
  try {
    await Auth.signOut();
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const initUser = createAsyncThunk<AuthState>(
  "initUser",
  async (req, thunkAPI) => {
    try {
      const currentCognitoUser =
        (await Auth.currentAuthenticatedUser()) as CognitoUser;
      const tokens = await getTokens();
      window.localStorage.setItem("access-token", tokens.accessToken);
      initHttpClient();
      const userAttributes = await Auth.userAttributes(currentCognitoUser);
      //console.log(currentCognitoUser.getSignInUserSession()?.getAccessToken().payload['cognito:groups'])
      const mappedUserData = mapUserData(currentCognitoUser, userAttributes);
      const groups = currentCognitoUser.getSignInUserSession()?.getAccessToken()
        .payload["cognito:groups"];
      return {
        authenticated: true,
        accessToken: tokens.accessToken,
        profile: {
          id: mappedUserData.sub,
          email: mappedUserData.email,
          firstName: mappedUserData.given_name,
          lastName: mappedUserData.family_name,
          phone: mappedUserData.phone_number,
          verified: mappedUserData.email_verified === "true",
          groups,
          invoice: {
            np: {
              firstName: mappedUserData["custom:inv:np:first_name"],
              lastName: mappedUserData["custom:inv:np:last_name"],
              email: mappedUserData["custom:inv:np:email"],
              street: mappedUserData["custom:inv:np:street"],
              houseNr: mappedUserData["custom:inv:np:house_nr"],
              apartmentNr: mappedUserData["custom:inv:np:apartment_nr"],
              postCode: mappedUserData["custom:inv:np:post_code"],
              city: mappedUserData["custom:inv:np:city"],
            },
            b: {
              companyName: mappedUserData["custom:inv:b:company_name"],
              nip: mappedUserData["custom:inv:b:nip"],
              email: mappedUserData["custom:inv:b:email"],
              street: mappedUserData["custom:inv:b:street"],
              houseNr: mappedUserData["custom:inv:b:house_nr"],
              apartmentNr: mappedUserData["custom:inv:b:apartment_nr"],
              postCode: mappedUserData["custom:inv:b:post_code"],
              city: mappedUserData["custom:inv:b:city"],
            },
          },
        },
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.authenticated = true;
      state.accessToken = action.payload.accessToken;
      state.profile = action.payload.profile;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.authenticated = false;
      state.accessToken = null;
      state.profile = null;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.authenticated = false;
      state.accessToken = null;
      state.profile = null;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.error = action.error;
      state.authenticated = false;
      state.accessToken = null;
      state.profile = null;
    });
    builder.addCase(initUser.fulfilled, (state, action) => {
      state.authenticated = true;
      state.accessToken = action.payload.accessToken;
      state.profile = action.payload.profile;
    });
    builder.addCase(initUser.rejected, (state, action) => {
      state.error = action.error;
      state.authenticated = false;
      state.accessToken = null;
      state.profile = null;
    });
  },
});
