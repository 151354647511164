import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <div className="app-layout" style={{ display: "flex" }}>
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default AppLayout;
