import { Button, Grid, Typography } from "@mui/material";
import SimpleItem from "../../../../common/components/DetailsPage/components/SimpleItem";
import InfoIcon from "@mui/icons-material/Info";
import DetailsContainer from "../../../../common/components/DetailsPage/components/DetailsContainer";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Box } from "@mui/system";
import AlertDialog from "../../../../common/components/Dialogs/AlertDialog/AlertDialog";
import { AlertDialogStateType } from "../../../../common/components/Dialogs/AlertDialog/AlertDialog";
import { useState } from "react";
import { Profile } from "../SingleUser";
import { CompanyData, NaturalPersonData } from "../../../Auth/Auth.reducers";
import { http } from "../../../../config/http";
import { API_URL } from "../../../../config/config";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  userData: Profile;
  getUserData: () => Promise<void>;
};

type ButtonTypes = "reset" | "block" | "remove";

const getSharedData = (data: CompanyData | NaturalPersonData) => {
  const sharedData = [
    {
      title: "Email",
      value: data.email,
    },
    {
      title: "Ulica",
      value: data.street,
    },
    {
      title: "Numer domu",
      value: data.houseNr,
    },
    {
      title: "Nr. mieszkania",
      value: data.apartmentNr,
    },
    {
      title: "Kod pocztowy",
      value: data.postCode,
    },
    {
      title: "Miasto",
      value: data.city,
    },
  ];

  return sharedData;
};

const BasicInfo = ({ userData, getUserData }: Props) => {
  const {
    enabled,
    invoice: { b, np },
    personalData: { name, surname, email, phone },
  } = userData;
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useState<AlertDialogStateType | null>(
    null
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const closeAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const confirmPasswordReset = async () => {
    try {
      http.post(`${API_URL}plot-analysis/users/${id}/reset_password`);
      await getUserData();
      closeAlertDialog();
    } catch (e) {
      console.log(e);
    }
  };

  const confirmAccountBlock = async () => {
    try {
      await http.post(
        `${API_URL}plot-analysis/users/${id}/${enabled ? "disable" : "enable"}`
      );
      await getUserData();
      closeAlertDialog();
    } catch (e) {
      console.log(e);
    }
  };

  const confirmAccountRemove = async () => {
    try {
      await http.delete(`${API_URL}plot-analysis/users/${id}`);
      closeAlertDialog();
      navigate("/users");
    } catch (e) {
      console.log(e);
    }
  };

  const alertDialogData = {
    reset: {
      title: "Reset hasła",
      text: "Czy na pewno chcesz zresetować hasło?",
      onSubmit: confirmPasswordReset,
    },
    block: {
      title: `${enabled ? "Zablokuj" : "Odblokuj"} konto`,
      text: `Czy na pewno chcesz ${
        enabled ? "zablokować" : "odblokować"
      } to konto?`,
      onSubmit: confirmAccountBlock,
    },
    remove: {
      title: "Usuń konto",
      text: "Czy na pewno chcesz usunąć to konto?",
      onSubmit: confirmAccountRemove,
    },
  };

  const handleProperAction = (type: ButtonTypes) => {
    const properActionData = alertDialogData[type];
    setOpenAlertDialog(!!properActionData);
    setAlertDialog(properActionData);
  };

  const basicData = [
    {
      title: "Imię",
      data: name,
    },
    {
      title: "Nazwisko",
      data: surname,
    },
    {
      title: "Email",
      data: email,
    },
    {
      title: "Numer telefonu",
      data: phone,
    },
  ];

  const addressData = [
    {
      title: "Osoba Fizyczna",
      data: [
        {
          title: "Imię",
          value: np.firstName,
        },
        {
          title: "Nazwisko",
          value: np.lastName,
        },
        ...getSharedData(np),
      ],
    },
    {
      title: "Firma",
      data: [
        {
          title: "Nazwa firmy",
          value: b.companyName,
        },
        {
          title: "NIP",
          value: b.nip,
        },
        ...getSharedData(b),
      ],
    },
  ];

  return (
    <>
      <DetailsContainer title="Podstawowe informacje" titleIcon={InfoIcon}>
        <Grid container spacing={1}>
          {basicData.map((item) => (
            <Grid item xs={12} md={6} lg={4} key={item.title}>
              <Typography sx={{ fontWeight: "bold" }}>{item.title}</Typography>
              <Typography gutterBottom sx={{ wordBreak: "break-word" }}>
                {item.data || "n/a"}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              mr: 2,
              mt: 2,
            }}
            onClick={() => {
              handleProperAction("reset");
            }}
          >
            Zresetuj hasło
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{
              mr: 2,
              mt: 2,
            }}
            onClick={() => {
              handleProperAction("block");
            }}
          >
            {enabled ? "Zablokuj" : "Odblokuj"} konto
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              mr: 2,
              mt: 2,
            }}
            onClick={() => {
              handleProperAction("remove");
            }}
          >
            Usuń konto
          </Button>
        </Box>
      </DetailsContainer>
      <DetailsContainer title="Dane bilingowe" titleIcon={HomeWorkIcon}>
        <Grid container spacing={3}>
          {addressData &&
            addressData.map(({ title, data }) => (
              <Grid item xs={12} lg={6} key={title}>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
                {data[0].value ? (
                  data.map((item) => (
                    <SimpleItem
                      title={item.title}
                      data={item.value}
                      key={item.title}
                    />
                  ))
                ) : (
                  <p>Brak danych</p>
                )}
              </Grid>
            ))}
        </Grid>
      </DetailsContainer>
      <AlertDialog
        open={!!openAlertDialog}
        handleClose={closeAlertDialog}
        onSubmit={alertDialog?.onSubmit}
        title={alertDialog?.title || ""}
        text={alertDialog?.text || ""}
      />
    </>
  );
};

export default BasicInfo;
