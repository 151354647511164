export interface GroupsUsersState {
  notifications: any[];
  latestNotification: any;
}

export enum NotificationVariant {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export type Notification = {
  text: string;
  variant: NotificationVariant;
  coloredText?: string;
  button?: boolean;
};

export type Action = {
  payload: Notification;
};

export interface MessageProps {
  notification: {
    variant: string;
    text: string;
    coloredText?: string;
    button?: boolean;
  };
}
