import "./SearchBox.scss";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const SearchBox = ({ currentSearchParams }: any) => {
  const [time, setTime] = useState<any>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnChange = (e: any) => {
    const search = () => {
      setSearchParams({
        ...currentSearchParams,
        page: "0",
        searchQuery: e.target.value,
      });
    };
    if (time) clearTimeout(time);
    setTime(setTimeout(() => search(), 300));
  };

  return (
    <TextField
      label="Wyszukaj"
      variant="standard"
      className="search-box"
      defaultValue={searchParams.get("searchQuery")}
      onChange={handleOnChange}
    />
  );
};

export default SearchBox;
