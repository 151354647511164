import "./ListContainer.scss";
import SearchBox from "../SearchBox/SearchBox";
import {
  Container,
  Stack,
  Typography,
  SvgIconTypeMap,
  CircularProgress,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Props = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  titleIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  children: React.ReactNode;
  loading?: boolean;
  currentSearchParams?: any;
};

const ListContainer = ({
  children,
  title,
  loading,
  titleIcon,
  currentSearchParams,
}: Props) => {
  const TitleIcon = titleIcon;

  return (
    <Container maxWidth="lg" sx={{ my: 6 }} className="list-container">
      <Stack direction="row" alignItems="center" gap={1} paddingBottom={3}>
        <TitleIcon fontSize="large" color="primary" />
        <Typography variant="h4">{title}</Typography>
      </Stack>
      <Container
        maxWidth="md"
        className={`list-container-inner${loading ? " loading" : ""}`}
      >
        <SearchBox currentSearchParams={currentSearchParams} />
        {children}
      </Container>
      {loading && (
        <div className="list-container-loader">
          <CircularProgress sx={{ display: "block" }} />
        </div>
      )}
    </Container>
  );
};

export default ListContainer;
