/* eslint-disable indent */

export function translate(message: string) {
  switch (message) {
    case "Invalid phone number format.":
      return "Niepoprawny numer telefonu.";
    case "Invalid code provided, please request a code again.":
      return "Podano niepoprawny kod.";
    case "An account with the given email already exists.":
      return "Konto z podanym adresem e-mail już istnieje.";
    case "ULDK API ERROR: Plot ID is not valid":
      return "Niepoprawny numer działki.";
    case "Entity already exists!":
      return "Ta działka została już przeanalizowana.";
    case "Invalid verification code provided, please try again.":
      return "Niepoprawny kod potwierdzający.";
    case "Attempt limit exceeded, please try after some time.":
      return "Przekroczono dopuszczalną ilość prób. Prosimy spróbować ponownie za jakiś czas.";
    case "Password does not conform to policy: Password not long enough":
      return "Hasło musi składać się z przynajmniej 8 znaków.";
    case "Password attempts exceeded":
      return "Zbyt wiele prób logowania. Spróbuj ponownie później.";
    case "Incorrect username or password.":
      return "Niepoprawny adres email lub hasło";
    default:
      return "Wystąpił błąd. Prosimy spróbować ponownie za jakiś czas.";
  }
}
export const analysesStatuses = [
  {
    label: "wszystkie",
    value: "all",
  },
  {
    label: "w przygotowaniu",
    value: "in_progress",
  },
  {
    label: "gotowy",
    value: "completed",
  },
];

export const getStatusLabel = (value: string) => {
  const status = analysesStatuses.find((e) => e.value === value);

  return status?.label || "n/a";
};
