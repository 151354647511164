import {
  Container,
  Paper,
  Stack,
  Typography,
  SvgIconTypeMap,
  CircularProgress,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Props = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  titleIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  children: React.ReactNode;
  loading?: boolean;
};

const DetailsContainer = ({ children, title, titleIcon, loading }: Props) => {
  const TitleIcon = titleIcon;

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Stack direction="row" alignItems="center" gap={1} paddingBottom={3}>
        <TitleIcon fontSize="large" color="primary" />
        <Typography variant="h5">{title}</Typography>
      </Stack>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
        ) : (
          children
        )}
      </Paper>
    </Container>
  );
};

export default DetailsContainer;
