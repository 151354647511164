import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useParams } from "react-router-dom";
import BasicInfo from "./components/BasicInfo";
import DetailsPageWrapper from "../../../common/components/DetailsPage/DetailsPageWrapper";
import BillingInfo from "./components/BillingInfo";
import ReportsList from "./components/ReportsList";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { http } from "../../../config/http";
import { API_URL } from "../../../config/config";
import { InvoiceData } from "../../Auth/Auth.reducers";

type PersonalData = {
  name: string;
  surname: string;
  email: string;
  phone: string;
};

export interface Profile {
  personalData: PersonalData;
  enabled?: boolean;
  timeInApp: number;
  invoice: InvoiceData;
}

const mapUserData = (user: any) => {
  const attributes = user.UserAttributes.reduce(
    (obj: any, item: any) => ((obj[item.Name] = item.Value), obj),
    {}
  );
  const mappedUserData = {
    ...user,
    ...attributes,
  };

  return {
    id: mappedUserData.sub,
    enabled: user.Enabled,
    personalData: {
      name: mappedUserData.given_name,
      surname: mappedUserData.family_name,
      email: mappedUserData.email,
      phone: mappedUserData.phone_number,
      verified: mappedUserData.email_verified === "true",
    },
    timeInApp: 50,
    invoice: {
      np: {
        firstName: mappedUserData["custom:inv:np:first_name"],
        lastName: mappedUserData["custom:inv:np:last_name"],
        email: mappedUserData["custom:inv:np:email"],
        street: mappedUserData["custom:inv:np:street"],
        houseNr: mappedUserData["custom:inv:np:house_nr"],
        apartmentNr: mappedUserData["custom:inv:np:apartment_nr"],
        postCode: mappedUserData["custom:inv:np:post_code"],
        city: mappedUserData["custom:inv:np:city"],
      },
      b: {
        companyName: mappedUserData["custom:inv:b:company_name"],
        nip: mappedUserData["custom:inv:b:nip"],
        email: mappedUserData["custom:inv:b:email"],
        street: mappedUserData["custom:inv:b:street"],
        houseNr: mappedUserData["custom:inv:b:house_nr"],
        apartmentNr: mappedUserData["custom:inv:b:apartment_nr"],
        postCode: mappedUserData["custom:inv:b:post_code"],
        city: mappedUserData["custom:inv:b:city"],
      },
    },
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const SingleUser = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<Profile | null>(null);

  const getUserData = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await http.get(`${API_URL}plot-analysis/users/${id}`);
      setUserData(mapUserData(data));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DetailsPageWrapper
      title="Informacje o użytkowniku"
      titleIcon={AccountBoxIcon}
      error={!loading && !userData}
    >
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "100px auto" }} />
      ) : (
        <Box sx={{ width: "auto" }}>
          <Box>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Podstawowe informacje" {...a11yProps(0)} />
              <Tab label="Lista raportów" {...a11yProps(1)} />
              <Tab label="Historia płatności" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {userData && (
              <BasicInfo userData={userData} getUserData={getUserData} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReportsList />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BillingInfo />
          </TabPanel>
        </Box>
      )}
    </DetailsPageWrapper>
  );
};

export default SingleUser;
