import React from "react";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";

export const PublicRoute: React.FC<RouteProps> = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  if (isAuthenticated === null) {
    return <></>;
  }
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
