/* eslint-disable indent */
import DetailsPageWrapper from "../../../common/components/DetailsPage/DetailsPageWrapper";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ReportSection from "./components/ReportSection";
import InfoIcon from "@mui/icons-material/Info";
import DetailsContainer from "../../../common/components/DetailsPage/components/DetailsContainer";
import BookIcon from "@mui/icons-material/Book";
import BoltIcon from "@mui/icons-material/Bolt";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { http } from "../../../config/http";
import { API_URL } from "../../../config/config";
import { useParams, useSearchParams } from "react-router-dom";

const properBoolText = (data: boolean) => {
  return data ? "Tak" : "Nie";
};

type ReportType = {
  external_ids?: string[];
  area?: number;
  voivodeship?: string;
  county?: string;
  commune?: string;
  region?: string;
  parcel?: string;
  electricity?: any[];
  layers?: any[];
  grounds_district_portal?: any[];
  grounds?: any[];
  distances?: string;
  urban_development_plan?: {
    has_urban_development_plan: string;
  };
  plot_analysis: {
    latitude?: number;
    longitude?: number;
    altitude?: string;
  };
  land_register?: any[];
  id?: string;
  task?: string;
};

const SingleReport = () => {
  const { id: plotId } = useParams();
  const [searchParams] = useSearchParams();
  const [reportData, setReportData] = useState<ReportType>({
    plot_analysis: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const ownerId = searchParams.get("owner_id");

  const {
    external_ids,
    area,
    voivodeship,
    county,
    commune,
    electricity,
    layers,
    grounds_district_portal,
    grounds,
    urban_development_plan,
    plot_analysis,
    land_register,
  } = reportData;

  useEffect(() => {
    const getReportData = async () => {
      setIsLoading(true);
      try {
        const { data } = await http.get(
          `${API_URL}plot-analysis/admin/multiplots/${plotId}?user_id=${ownerId}`
        );
        setReportData(data);
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    getReportData();
  }, [ownerId, plotId]);

  const forests = layers?.find((layer) => layer.type === "forests");
  const floodplains = layers?.find((layer) => layer.type === "floodplains");
  const environmentProtections = layers?.find(
    (layer) => layer.type === "environment_protections"
  );
  const groundsLayers = layers?.filter(
    (layer) => layer.type === "grounds" && layer.description
  );

  const cableData = electricity?.find((item) => item.type === "cable");
  const substationData = electricity?.find(
    (item) => item.type === "substation"
  );

  const { latitude, longitude, altitude } = plot_analysis;

  const groundsClasses = () =>
    groundsLayers?.map((item) => `${item.description} `).join("");

  const landRegisterInfo =  land_register?.map((item: any) => 
    `${item.external_id}: ${item.is_paid ? item.identity : "brak"}`
  );

  const basicData = {
    title: "Informacje ogólne",
    data: [
      {
        title: "Lokalizacja",
        data: [
          {
            name: "Gmina",
            value: commune,
          },
          {
            name: "Powiat",
            value: county,
          },
          {
            name: "Województwo",
            value: voivodeship,
          },
          {
            name: "Szerokość geograficzna",
            value: latitude?.toString().substring(0, 5) || "-",
          },
          {
            name: "Długość geograficzna",
            value: longitude?.toString().substring(0, 5) || "-",
          },
          {
            name: "Wysokość n.p.m.",
            value: altitude,
          },
        ],
      },
      {
        title: "Powierzchnia",
        data: [
          {
            name: "Powierzchnia w hektarach",
            value: area ? `${(area / 10000).toFixed(2)} ha` : "-",
          },
        ],
      },
      {
        title: "Utrudnienia",
        data: [
          {
            name: "Zalesienie",
            value: properBoolText(!!forests?.percent),
          },
          {
            name: "Powierzchnia zalesienia",
            value:
              forests?.percent && area
                ? `${((area / 10000) * forests.percent).toFixed(2)} ha`
                : "0 ha",
          },
          {
            name: "Tereny zalewowe",
            value: properBoolText(!!floodplains?.percent),
          },
          {
            name: "Ograniczenia środowiskowe",
            value: properBoolText(!!environmentProtections?.percent),
          },
          {
            name: "Typ ograniczenia",
            value: !environmentProtections?.percent
              ? "-"
              : environmentProtections.description
              ? environmentProtections.description
              : "brak informacji",
          },
        ],
      },
      {
        title: "Gleby",
        data: [
          {
            name: "Klasa bonitacyjna gleby",
            value: groundsClasses(),
          },
        ],
      },
      {
        title: "Dostęp do drogi",
        data: [
          {
            name: "Droga publiczna",
            value: "-",
          },
          {
            name: "Droga",
            value: "-",
          },
        ],
      },
      {
        title: "MPZP",
        data: [
          {
            name: "Miejscowy Plan Zagospodarowania Przestrzeni",
            value: urban_development_plan?.has_urban_development_plan,
          },
        ],
      },
    ],
  };

  const electricityData = {
    title: "Parametry energetyczne",
    data: [
      {
        title: "Najblizsza linia energetyczna",
        data: [
          {
            name: "Odległość",
            value: cableData?.distance
              ? `${Math.round(cableData?.distance)} m`
              : "Brak danych",
          },
          {
            name: "Moc",
            value: cableData?.voltages[0]
              ? `${cableData?.voltages[0] / 1000} kV`
              : "Brak danych",
          },
        ],
      },
      {
        title: "Najblizszy GPZ",
        data: [
          {
            name: "Odległość",
            value: substationData?.distance
              ? `${Math.round(substationData?.distance)} m`
              : "Brak danych",
          },
          {
            name: "Moc",
            value: substationData?.voltages[0]
              ? `${substationData?.voltages[0] / 1000} kV`
              : "Brak danych",
          },
        ],
      },
    ],
  };

  return (
    <DetailsPageWrapper
      title={`Raport nr. ${external_ids?.join(", ") || "..."}`}
      titleIcon={AnalyticsIcon}
      loading={isLoading}
      error={!isLoading && !external_ids}
    >
      <ReportSection
        containerTitle={basicData.title}
        titleIcon={InfoIcon}
        data={basicData.data}
      />
      <ReportSection
        containerTitle={electricityData.title}
        titleIcon={BoltIcon}
        data={electricityData.data}
      />
      <DetailsContainer title="Numer księgi wieczystej" titleIcon={BookIcon}>
        {landRegisterInfo?.map((item: any) => (
          <Typography variant="h6" sx={{ fontWeight: "bold" }} mb={1}>
           {item}
          </Typography>
        ))}
      </DetailsContainer>
    </DetailsPageWrapper>
  );
};

export default SingleReport;
