import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Link, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { translate } from "../../../common/helpers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { Link as RouterLink } from "react-router-dom";
import LoginFlowWrapper from "../../../common/components/LoginFlowWrapper/LoginFlowWrapper";
import { addNewNotification } from "../../../common/components/Notification/Notification";

interface FormValuesProps {
  code: string;
  password: string;
}

const NewPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (!searchParams.get("email")) {
      navigate("/");
    }

    setEmail(searchParams.get("email")?.replace(" ", "+") as string);
  }, [navigate, searchParams]);

  const submitForm = async (form: FormValuesProps) => {
    try {
      await Auth.forgotPasswordSubmit(email, form.code, form.password);
      navigate("/login?updated=true");
    } catch (e) {
      if (e instanceof Error) {
        addNewNotification({
          text: translate(e.message),
          variant: NotificationVariant.ERROR,
        });
      }
    }
  };

  return (
    <LoginFlowWrapper
      title="Nowe hasło"
      text="Aby zakończyć zmianę hasło wpisz kod aktywacyjny, który został wysłany na podany przez Ciebie adres e-mail*"
    >
      <Formik<FormValuesProps>
        initialValues={{
          code: "",
          password: "",
        }}
        validationSchema={Yup.object({
          code: Yup.string().required("pole jest wymagane"),
          password: Yup.string()
            .required("pole jest wymagane")
            .matches(/^(?=.*[0-9])/, "hasło musi zawierać cyfrę")
            .min(8, "hasło jest zbyt krótkie"),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
        component={(props) => <Form {...{ ...props, email }} />}
      />
      <Link to="/login" variant="body2" component={RouterLink}>
        Wróć do logowania
      </Link>
    </LoginFlowWrapper>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        autoComplete="off"
        error={!!errors.code && touched.code}
        helperText={touched.code ? errors.code : null}
        label="Kod potwierdzający"
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        autoComplete="off"
        type="password"
        error={!!errors.password && touched.password}
        helperText={touched.password ? errors.password : null}
        label="Nowe hasło"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Wyślij
      </Button>
    </form>
  );
};

export default NewPassword;
