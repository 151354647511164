import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { useEffect } from "react";
import { Box, Button, TableSortLabel } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import ListContainer from "../../../common/components/ListContainer/ListContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getUsersList } from "./UsersList.reducers";
import { visuallyHidden } from "@mui/utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GroupIcon from "@mui/icons-material/Group";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  alignLeft?: boolean;
  nonSortable?: boolean;
}

export type UserItem = {
  id: number;
  name: string;
  created_at: string;
  reportsCount: number;
  isAdmin: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: "given_name",
    alignLeft: true,
    disablePadding: true,
    label: "Imię i nazwisko",
  },
  {
    id: "created_at",
    disablePadding: false,
    label: "Data rejestracji",
  },
  {
    id: "number_of_analyses",
    disablePadding: false,
    label: "Ilość raportów",
  },
  {
    id: "is_admin",
    disablePadding: false,
    label: "Administrator",
  },
  {
    id: "link",
    disablePadding: false,
    label: "",
    nonSortable: true,
  },
];

export default function UsersTable() {
  const dispatch = useAppDispatch();
  const {
    total,
    items: usersList,
    loading,
    error,
  } = useAppSelector((state) => state.users);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("searchQuery") || "";
  const page = Number(searchParams.get("page")) || 0;
  const limit = searchParams.get("limit") || "10";
  const ordering = searchParams.get("ordering") || "created_at";

  const currentSearchParams = {
    page,
    limit,
    ordering,
    searchQuery,
  };

  useEffect(() => {
    const fetchUsersList = async () => {
      await dispatch(
        getUsersList({
          searchQuery,
          page,
          limit,
          ordering,
        })
      );
    };

    fetchUsersList();
  }, [dispatch, limit, ordering, page, searchQuery]);

  const changePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => {
    setSearchParams({ ...currentSearchParams, page: value.toString() });
  };

  const changeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParams({
      ...currentSearchParams,
      page: "0",
      limit: parseInt(event.target.value, 10).toString(),
    });
  };

  if (error) {
    return (
      <h1 className="state-msg">
        wystąpił błąd przy wczytywaniu listy użytkowników
      </h1>
    );
  }

  const isAscending = ordering.charAt(0) !== "-";

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const currentOrderBy = ordering.includes(property) && isAscending;
    setSearchParams({
      ...currentSearchParams,
      page: "0",
      ordering: `${currentOrderBy ? "-" : ""}${property}`,
    });
  };

  return (
    <ListContainer
      title="użytkownicy"
      loading={loading}
      titleIcon={GroupIcon}
      currentSearchParams={currentSearchParams}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ height: "75px" }}>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={isAscending ? "asc" : "desc"}
                >
                  {headCell?.nonSortable ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={ordering.includes(headCell.id)}
                      direction={isAscending ? "asc" : "desc"}
                      onClick={(e) => handleRequestSort(e, headCell.id)}
                    >
                      {headCell.label}
                      {ordering.includes(headCell.id) ? (
                        <Box component="span" sx={visuallyHidden}>
                          {isAscending
                            ? "sorted ascending"
                            : "sorted descending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map(
              ({ id, name, created_at, reportsCount, isAdmin }: UserItem) => (
                <TableRow key={id}>
                  <TableCell>{name || "-"}</TableCell>
                  <TableCell>
                    {(created_at &&
                      new Intl.DateTimeFormat("pl-PL", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(created_at))) ||
                      "-"}
                  </TableCell>
                  <TableCell>{reportsCount || "0"}</TableCell>
                  <TableCell>
                    {isAdmin ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="warning" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/users/${id}`}>
                      <Button
                        endIcon={<ArrowForwardIcon />}
                        variant="outlined"
                        sx={{ textTransform: "none" }}
                      >
                        zobacz
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              )
            )}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={4}
                count={total}
                rowsPerPage={Number(limit)}
                page={page}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
                labelRowsPerPage="ilość wierszy"
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ListContainer>
  );
}
