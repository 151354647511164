import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../../config/http";
import { API_URL } from "../../../config/config";

type ReportQueryParams = {
  limit?: string;
  ordering?: string;
  index?: string;
  name?: string;
  status?: string;
};

interface AnalysesState {
  total: number;
  items?: any;
  loading: boolean;
  error: boolean;
  success: boolean;
}

const initialState: AnalysesState = {
  total: 0,
  items: [],
  loading: false,
  error: false,
  success: false,
};

export const getAnalysesList = createAsyncThunk<
  AnalysesState,
  {
    searchQuery: string;
    statusFilter: string;
    page: number;
    limit: string;
    ordering: string;
  }
>(
  "analyses",
  async (
    { searchQuery, statusFilter, page, limit, ordering },
    { rejectWithValue }
  ) => {
    const params: ReportQueryParams = {
      limit: limit,
      ordering: ordering,
    };

    if (page) {
      params["index"] = (page * Number(limit)).toString();
    }
    if (searchQuery.length > 0) {
      params["name"] = searchQuery;
    }
    if (statusFilter.length > 0 && statusFilter !== "all") {
      params["status"] = statusFilter;
    }

    const query = new URLSearchParams(params).toString();

    try {
      const response = await http.get(
        `${API_URL}plot-analysis/tasks_all?${query}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const analysesSlice = createSlice({
  name: "analyses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalysesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnalysesList.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.items = action.payload.items;
      state.error = false;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getAnalysesList.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});
